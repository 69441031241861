import React from 'react'
import "preline/preline";
import { BrowserRouter } from 'react-router-dom';

import HandleRoutes from "./HandleRoutes"

const App = () => {

  return (
    <>


            <BrowserRouter>

              <HandleRoutes />

            </BrowserRouter>

    </>
  )
}

export default App