import React from 'react'

const ComingSoon = () => {
  return (
    <>

        <div className="w-full h-full flex items-center justify-center">
            Coming Soon
        </div>

    </>
  )
}

export default ComingSoon