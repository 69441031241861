import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ComingSoon from './pages/ComingSoon';

const HandleRoutes = ({userB}) => {

  const location = useLocation();

  useEffect(() => {
      window.HSStaticMethods.autoInit();
    }, [location.pathname]);

  return (
    <>

            <Routes>

                <Route path='/' element={<HomePage />} />

                <Route path='*' element={<ComingSoon />} />

            </Routes>

    </>
  )

}

export default HandleRoutes